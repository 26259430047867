import React, { useState, Fragment, useEffect } from 'react';
import { useLocation } from '@reach/router';
import Layout from '../components/layout';
import Tabs from '../components/tabs/tabs.component';
import Accordion from '../components/accordion/accordion.component';
import '../styles/app.scss';

import {
  LEGAL_TERMS_AND_CONDITIONS_DATA,
  LEGAL_INFO_SITE_MAP_DATA,
  LEGAL_INFO_MORTGAGE_DATA,
  LEGAL_INFO_CUSTOMER_PRIVACY_DATA
} from '../data/criteria.data';


export default function LegalInformations() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const location = useLocation();

  const handleClick = (event) => {
    if (event.target.hasAttribute('data-tealium') || event.target.parentNode.hasAttribute('data-tealium')) {
      if (typeof window !== "undefined") {
        if(window.utag){
          window.utag.link({page_subcategory: "legal information"})
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);


  if (typeof window !== "undefined") {
    if(window.utag){
      window.utag.view({page_subcategory: "legal information"})
    }
  }

  useEffect(() => {
    const hash = location.hash.slice(1) // remove the '#' from the hash
    console.log("Current hash:", hash);

    // Perform actions based on the hash value
    if (hash === "accessibility") {
      setSelectedTabIndex(1);
    } else if (hash === "sitemap") {
      setSelectedTabIndex(2);
    } else {
      setSelectedTabIndex(0);
    }

    if (typeof window !== `undefined`) {
      window.scrollTo({ top: 0 });
    }
  }, [location.hash])




  return (
    <Layout
      title='Legal information'
      metaDescription="Legal information on HSBC website."
    >
      <div className="page-section row">
        <div className='col-xs-12 col-md-12'>
          <h1 className='page-title'>Legal information</h1>
          <br />
          <br />
          <Tabs
            tabs={['Website Terms And Conditions', 'Accessibility', 'Site map', 'Morgage Intermediary Portal Privacy Notice', 'Customer privacy notice']}
            activeTabIndex={selectedTabIndex}
            setActiveTab={setSelectedTabIndex}
            additionalClassName='tabs--horizontal'
            tabContent={[
              [
                <Fragment key={1}>
                  <h2 className='strong'>Website Terms and Conditions</h2><br />
                  <p>This document sets out the terms and conditions ('Terms') governing your use of this website, including <a href="https://portal.intermediaries.hsbc.co.uk">portal.intermediaries.hsbc.co.uk</a> (the 'Portal') (together the 'Site'). Use of the Site includes accessing, browsing or registering to use the Site.</p>
                  {
                    LEGAL_TERMS_AND_CONDITIONS_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true}>
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </Fragment>
              ],
              [
                <Fragment key={2}>
                  <h2><strong>Accessibility</strong></h2><br />
                  <ul className='accessibility-list'>
                    <li>We only use strong colour contrasts</li>
                    <li>Headings are used correctly to make them readable through screen readers</li>
                    <li>Links do what they say. We don't use 'click here'</li>
                    <li>Tables are laid out in tabular form with headings and summaries</li>
                    <li>We use style sheets to determine the presentation of our web site - this assists those using screen reading devices</li>
                    <li>Where we use images, we use alternative text so that those people who cannot see the image can read the text. These can be read by screen reading devices.</li>
                  </ul>
                  <h3><strong>System requirement</strong></h3>
                  <p>Personal Internet Banking is available to both PC and Apple Mac users with modern internet enabled machines.<br />Range of browsers and operating systems that we support:</p>
                  <p><strong>Browsers</strong></p>
                  <ul className='accessibility-list'>
                    <li>Internet Explorer 11</li>
                    <li>Safari 6 and above</li>
                    <li>Google Chrome latest</li>
                    <li>We are accessible in various smart phone browsers</li>
                  </ul>
                  <p><strong>Operating systems</strong></p>
                  <ul className='accessibility-list'>
                    <li>Windows 7, 10</li>
                    <li>Mac OS X 10.10 and above</li>
                  </ul>
                  <p>Browser updates are available free of charge from each browser provider. Operating systems can be purchased from software vendors.</p>
                  <p>We will update our list of supported browsers and operating systems from time to time as the software used by our customers changes.</p>
                  <p>The details are correct as of 1 October 2015.</p>
                  <p><strong>Reasons to upgrade your browser</strong></p>
                  <ul className='accessibility-list'>
                    <li>New versions are more secure</li>
                    <li>New versions are faster</li>
                    <li>Improved browsing experience</li>
                  </ul>
                  <p>Browser updates are available from Microsoft, Firefox, Safari, Google.</p>
                </Fragment>
              ],
              [
                <React.Fragment key={3}>
                  <h2 className='strong'>Site map</h2>
                  {
                    LEGAL_INFO_SITE_MAP_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true} >
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ],
              [
                <React.Fragment key={4}>
                  <h2 className='strong'>Privacy Notice</h2>
                  {
                    LEGAL_INFO_MORTGAGE_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true} >
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ],
              [
                <React.Fragment key={5}>
                  <h2 className='strong'>Customer privacy notice</h2>
                  {
                    LEGAL_INFO_CUSTOMER_PRIVACY_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true} >
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ],
            ]}
          />
        </div>
      </div>
    </Layout>
  );
}
